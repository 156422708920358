export const currencies = [
    {
        title: 'Thai Baht',
        value: 'thb',
        symbol: '฿'
    },
    {
        title: 'US Dollar',
        value: 'usd',
        symbol: '$'
    },
    {
        title: 'Euro',
        value: 'eur',
        symbol: '€'
    },
    {
        title: 'Chinese Yuan',
        value: 'rmb',
        symbol: 'CNY'
    },
    {
        title: 'GB Pound',
        value: 'gbp',
        symbol: '£'
    },
    {
        title: 'Australian Dollar',
        value: 'aud',
        symbol: 'AU$'
    },
    {
        title: 'Japanese Yen',
        value: 'jpy',
        symbol: '¥'
    }
]