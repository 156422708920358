'use client'
import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

const LinkComponent = ({ href, children, className }) => {
    // const segments = useSelectedLayoutSegments()
    const pathname = usePathname()
    const segments = pathname.split('/')

    let link = href
    const languages = ['en', 'th']
    if (segments.length > 1 && languages.includes(segments[1])) {
        link = `/${segments[1]}${href}`
    }

    return (
        <Link className={className} href={link}>{children}</Link>
    )
}

export default LinkComponent